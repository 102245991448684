import React, { Component } from "react";
import "./changeStatusModal.css";
import { updateBookingRedux } from "../../actions/index";
import { connect } from "react-redux";
import { toast } from "react-toastify";
class ChangeStatusModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      bookingStatus: "",
      chinaOffice: "",
      totalCost: "",
    };
  }

  handleSubmit = async (event) => {
    event.preventDefault();
    await this.props.bookingIdArray.forEach((bookingId) =>
      this.props.updateBookingRedux({ bookingId: bookingId, ...this.state })
    );
    toast.success("successfully updated booking status");
    this.setState({
      bookingStatus: "",
      chinaOffice: "",
      totalCost: "",
    });
    this.props.startToggleModal(null);
  };
  handleChange = (e) => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  };
  render() {
    return (
      <>
        <div
          className={
            this.props.toggleModal
              ? "modal fade show"
              : "modal fade show visible-modal"
          }
          id="request_popup"
          tabIndex="-1"
          role="dialog"
          aria-hidden="true"
        >
          <div
            className="modal-dialog modal-dialog-centered request_popup"
            role="document"
          >
            <div
              className="modal-content visible-modal-content-3"
              style={{ backgroundColor: "rgb(68 0 97)" }}
            >
              <div className="modal-body p-0">
                <section className="pos-rel bg-light-gray">
                  <div className="container-fluid p-3">
                    <a
                      onClick={() => this.props.startToggleModal(null)}
                      className="close"
                      data-dismiss="modal"
                      aria-label="Close"
                    >
                      <i
                        className="icofont-close-line"
                        style={{ color: "white" }}
                      ></i>
                    </a>
                    <div className="d-lg-flex justify-content-end no-gutters mb-spacer-md">
                      {/* <div className="col bg-fixed bg-img-7 request_pag_img">
                        &nbsp;
                      </div> */}

                      <div className="col">
                        <div className="px-3 m-5">
                          <h2
                            className="h2-xl mb-3 fw-6 pb-2"
                            style={{
                              color: "white",
                              textTransform: "none",
                              fontSize: "200%",
                              borderBottom: "2px dotted white",
                            }}
                          >
                            Change Status
                          </h2>
                          <form
                            onSubmit={this.handleSubmit}
                            noValidate="novalidate"
                            className="rounded-field mt-4"
                          >
                            <div className="form-row mb-4">
                              <div className="col">
                                <label
                                  style={{
                                    color: "white",
                                    marginBottom: "5px",
                                  }}
                                >
                                  Update Booking Status
                                </label>
                                <select
                                  title="Please choose a package"
                                  required
                                  name="bookingStatus"
                                  className="custom-select"
                                  aria-required="true"
                                  aria-invalid="false"
                                  onChange={this.handleChange}
                                  value={this.state.bookingStatus}
                                >
                                  <option value="">Select Status</option>
                                  <option value="Success">Success</option>
                                  <option value="Reject">Reject</option>
                                </select>
                              </div>
                            </div>
                            <div className="form-row mb-4">
                              <div className="col">
                                <label
                                  style={{
                                    color: "white",
                                    marginBottom: "5px",
                                  }}
                                >
                                  Select Office Address
                                </label>
                                <select
                                  title="Please choose a package"
                                  required
                                  name="chinaOffice"
                                  className="custom-select"
                                  aria-required="true"
                                  aria-invalid="false"
                                  onChange={this.handleChange}
                                  value={this.state.chinaOffice}
                                >
                                  <option value="">Select Address</option>
                                  <option value="BD (Saifulllah:+8801894895885) 37/2 Pritom-Zaman Tower, 10th Floor, suite 6A, Culvert Road, Dhaka-1000. Bangladesh">
                                    BD (Saifullah:+8801894895885) 37/2
                                    Pritom-Zaman Tower, 10th Floor, suite 6A,
                                    Culvert Road, Dhaka-1000. Bangladesh.
                                  </option>
                                  <option
                                    value="CHINA-AIR 岑先生(MrCen) 电话：18102777364
                                    Warehouse Address:
                                    广东省广州市白云区太和镇营溪村四社二街50号一楼仓库。
                                    收货人:客户的唛头 邮政编码:510445"
                                  >
                                    CHINA-AIR 岑先生(MrCen) 电话：18102777364
                                    Warehouse Address:
                                    广东省广州市白云区太和镇营溪村四社二街50号一楼仓库。
                                    收货人:客户的唛头 邮政编码:510445
                                  </option>
                                  <option value="THAILAND (NOK :+66 02-6239481-3) 314/1-2 ถ.จักรเพชร แขวงวังบูรพาภิรมย์​ เขตพระนคร กรุงเทพฯ​  10200">
                                    THAILAND (NOK :+66 02-6239481-3) 314/1-2
                                    ถ.จักรเพชร แขวงวังบูรพาภิรมย์​ เขตพระนคร
                                    กรุงเทพฯ​ 10200
                                  </option>
                                  <option
                                    value="Sea Warehouses-1 (DIYA-15180728459) 广东省佛山市南海区里水镇胜利工业区一路39号A6仓（壹米达物流仓储园）；
备注: 请各位供应商用绿色编织袋打包好，并贴好唛头和箱号，再送往仓厍，并提供电子版装箱单发到我微信，多谢合作！请在送货前电话联系我！"
                                  >
                                    Sea Warehouses-1 (DIYA-15180728459)
                                    广东省佛山市南海区里水镇胜利工业区一路39号A6仓（壹米达物流仓储园）；
                                    备注:
                                    请各位供应商用绿色编织袋打包好，并贴好唛头和箱号，再送往仓厍，并提供电子版装箱单发到我微信，多谢合作！请在送货前电话联系我！
                                  </option>
                                  <option
                                    value="Sea Warehouse-2 (DIYA-15180728459) 地址：广州市白云区西槎路同雅东街16号B5仓库     
备注: 请各位供应商用绿色编织袋打包好，并贴好唛头和箱号，再送往仓厍，并提供电子版装箱单发到我微信，多谢合作！请在送货前电话联系我！"
                                  >
                                    Sea Warehouse-2 (DIYA-15180728459)
                                    地址：广州市白云区西槎路同雅东街16号B5仓库 
                                       备注:
                                    请各位供应商用绿色编织袋打包好，并贴好唛头和箱号，再送往仓厍，并提供电子版装箱单发到我微信，多谢合作！请在送货前电话联系我！
                                  </option>
                                  <option
                                    value="SEA WAREHOUSE-3 (DIYA-15180728459) 广州市白云区黄石西路（青蓬食尚对面）石岗牌坊直入500米右转后岗工业区大雄仓储F栋
                                    送货时间 中午12点到晚上6点，送货时说明是梁生的货。
                                    备注: 请各位供应商用绿色编织袋打包好，并贴好唛头和箱号，再送往仓厍，并提供电子版装箱单发到我微信，多谢合作！请在送货前电话联系我！"
                                  >
                                    Sea Warehouse-3 (DIYA-15180728459)
                                    广州市白云区黄石西路（青蓬食尚对面）石岗牌坊直入500米右转后岗工业区大雄仓储F栋
                                    送货时间
                                    中午12点到晚上6点，送货时说明是梁生的货。
                                    备注:
                                    请各位供应商用绿色编织袋打包好，并贴好唛头和箱号，再送往仓厍，并提供电子版装箱单发到我微信，多谢合作！请在送货前电话联系我！
                                  </option>
                                  <option
                                    value=" India (Ashraful- +918327018844) Mohsin
                                    Square (Ground floor) Near Goltalla, Arafat
                                    Hotel,State Bank of India, Kolkata-700016,
                                    West Bengal."
                                  >
                                    India (Ashraful- +918327018844) Mohsin
                                    Square (Ground floor) Near Goltalla, Arafat
                                    Hotel,State Bank of India, Kolkata-700016,
                                    West Bengal.
                                  </option>
                                </select>
                              </div>
                            </div>
                            <div className="form-row mb-4">
                              <div className="col">
                                <label
                                  style={{
                                    color: "white",
                                    marginBottom: "5px",
                                  }}
                                >
                                  Total Cost
                                </label>
                                <input
                                  type="text"
                                  name="totalCost"
                                  placeholder="Enter total Cost"
                                  className="form-control"
                                  onChange={this.handleChange}
                                  value={this.state.totalCost}
                                  required
                                />
                              </div>
                            </div>

                            <div className="form-row">
                              <div
                                className="col pt-3"
                                style={{
                                  display: "flex",
                                  justifyContent: "flex-end",
                                }}
                              >
                                <button
                                  type="submit"
                                  className="btn btn-secondary"
                                >
                                  Update
                                  <i className="icofont-rounded-right"></i>
                                </button>
                              </div>
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default connect(null, { updateBookingRedux })(ChangeStatusModal);
