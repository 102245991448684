import axios from "axios";

export const sendNotifications = async (token, message) => {
  console.log("send notification is called!");
  var data = JSON.stringify({
    data: {},
    notification: {
      title: message.title,
      body: message.body,
    },
    // token
    to: `${token}`,
  });

  var config = {
    method: "post",
    url: "https://fcm.googleapis.com/fcm/send",
    headers: {
      Authorization:
        "key=AAAAqUmTVEs:APA91bEqY-KrcTs7aZPjl58IhoTNkziROHg_uc4uNTWa0T_4Hy_fTB8dmvY3haay_E298Y1b78kihZlzmrcIvLYvkIjv62TsIEuJ5xcVIwNP7UuQhJ3kbuvE4_mwfQu5FH8oAnWUF2gM",
      "Content-Type": "application/json",
    },
    data: data,
  };

  axios(config)
    .then((response) => {
      console.log(JSON.stringify(response.data));
    })
    .catch((error) => {
      console.log(error);
    });
};
